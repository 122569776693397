import { useLazyGetChartByIdQuery } from "@api/charts";

const useChartById = () => {
    const [getChartById, { isLoading, isError }] = useLazyGetChartByIdQuery();

    const getChartDataById = async (submoduleId: string, chartId: string, filters?: Record<string, string>, sortBy?: string, sortOrder?: string) => {
        try {
            let params: string[] = [];

            if (filters) {
                for (const [key, value] of Object.entries(filters)) {
                    if (value !== "All") {
                        params.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
                    }
                }
            }

            const filterParams = params.join('&');
            const sortParams = [
                sortBy ? `sortBy=${encodeURIComponent(sortBy)}` : '',
                sortOrder ? `sortOrder=${encodeURIComponent(sortOrder)}` : ''
            ].filter(Boolean).join('&');

            const queryParams = filterParams
                ? `?${filterParams}${sortParams ? `&${sortParams}` : ''}`
                : sortParams
                    ? `?${sortParams}`
                    : '';

            const response = await getChartById({ submoduleId, chartId, queryParams }).unwrap();

            return response?.data || null;

        } catch (err) {
            console.error('Error fetching chart data:', err);
        }
    };

    return { getChartDataById, isLoading, isError };
};

export default useChartById;
