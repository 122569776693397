import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { ReactComponent as ZeroDilutionIcon } from "../../../assets/icons/zero-dilution-icon.svg";
import { ReactComponent as FlexibleDisbursementsIcon } from "../../../assets/icons/flexible-disbursements-icon.svg";
import { ReactComponent as TransparentCoCIcon } from "../../../assets/icons/transparent-CoC-icon.svg";
import { ReactComponent as RiskProtectionIcon } from "../../../assets/icons/risk-protection-icon.svg";
import theme from "../../../theme";

const PvXPerksSection: React.FC = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const pvxPerks = [
    {
      title: "Zero equity dilution",
      subText:
        "Spend to your maximum potential without giving up any ownership",
      icon: (
        <ZeroDilutionIcon
          style={{
            flexShrink: 0,
            height: isMobile ? "60px" : "80px",
            width: isMobile ? "60px" : "80px",
          }}
        />
      ),
    },
    {
      title: "Flexible disbursements",
      subText:
        "Request the funds your business needs on a monthly basis and adjust freely in the future",
      icon: (
        <FlexibleDisbursementsIcon
          style={{
            flexShrink: 0,
            height: isMobile ? "60px" : "80px",
            width: isMobile ? "60px" : "80px",
          }}
        />
      ),
    },
    {
      title: "Transparent cost of capital",
      subText:
        "Understand the full cash cost of borrowing up front without any hidden fees",
      icon: (
        <TransparentCoCIcon
          style={{
            flexShrink: 0,
            height: isMobile ? "60px" : "80px",
            width: isMobile ? "60px" : "80px",
          }}
        />
      ),
    },
    {
      title: "Downside risk protection",
      subText: "We share the downside, you own the upside",
      icon: (
        <RiskProtectionIcon
          style={{
            flexShrink: 0,
            height: isMobile ? "60px" : "80px",
            width: isMobile ? "60px" : "80px",
          }}
        />
      ),
    },
  ];

  return (
    <Box
      sx={{
        marginX: isMobile ? "1.25rem" : "6.25rem",
        marginTop: "5rem",
        marginBottom: "3.5rem",
      }}
    >
      <Typography
        sx={{
          fontSize: isMobile ? "3.375rem" : "3.875rem",
          fontWeight: 600,
          lineHeight: isMobile ? "3.375rem" : "4.5rem",
          letterSpacing: isMobile ? "-3px" : "-4px",
          color: "#162936",
          padding: { xs: "2rem", lg: "1.25rem 2rem 2.25rem 1.25rem" },
        }}
      >
        <span style={{ color: "#989CA2" }}>Fuel your</span> game’s growth
      </Typography>
      <Box
        sx={{
          display: "grid",
          gap: "16px",
          gridTemplateColumns: {
            xs: "1fr",
            md: "repeat(2, 1fr)",
            xl: "repeat(4, 1fr)",
          },
        }}
      >
        {pvxPerks.map((perk) => (
          <Box
            sx={{
              padding: isMobile ? "2rem" : "1.25rem 2rem 2.25rem 1.25rem",
              background: "#F6F8F9",
              borderRadius: "24px",
              display: "flex",
              flexDirection: isMobile ? "row" : "column",
              gap: isMobile ? "1rem" : "0rem",
              justifyContent: isMobile ? "start" : "space-between",
              alignItems: isMobile ? "center" : "start",
            }}
          >
            {perk.icon}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column-reverse",
              }}
            >
              <Typography
                sx={{
                  color: "#162936B2",
                  fontWeight: 550,
                  fontSize: "1rem",
                  lineHeight: "20px",
                  letterSpacing: "-0.45px",
                  width: isMobile ? "100%" : "90%",
                }}
              >
                {perk.subText}
              </Typography>
              <Typography
                sx={{
                  color: "#162936",
                  fontWeight: 600,
                  fontSize: isMobile ? "1.75rem" : "2.5rem",
                  lineHeight: isMobile ? "1.5rem" : "2.5rem",
                  marginBottom: "1.5rem",
                  marginTop: isMobile ? "0px" : "6rem",
                  letterSpacing: isMobile ? "-1px" : "-1.9px",
                  width: isMobile ? "100%" : "80%",
                }}
              >
                {perk.title}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PvXPerksSection;
