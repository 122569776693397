import React from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface CustomDropwdonFieldProps {
  dropdownOptions: { id: number | string; label: string; icon?: string }[];
  handleChange: (event: SelectChangeEvent) => void;
  selectedOption: string;
}

const CustomDropdown: React.FC<CustomDropwdonFieldProps> = ({
  dropdownOptions,
  handleChange,
  selectedOption,
}) => {
  return (
    <Select
      onChange={handleChange}
      value={selectedOption || ""}
      sx={{
        boxShadow: "none",
        ".MuiOutlinedInput-notchedOutline": { borderStyle: "none" },
        "& .MuiSelect-select": {
          padding: 0,
        },
        ".MuiSvgIcon-root ": {
          fill: "#2F736E !important",
        },
        borderRadius: "8px",
        border: "1px solid #E8EAEB",
        paddingY: "0.375rem",
        paddingLeft: "1rem",
        paddingRight: "0.75rem",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        letterSpacing: "-0.35px",
        fontWeight: 700,
        color: "#6C6C6C",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "48px",
      }}
    >
      {dropdownOptions.map((option) => (
        <MenuItem
          key={option.id}
          value={option.id}
          sx={{
            display: "flex",
            gap: "4px",
            padding: "8px",
            fontSize: "14px",
            fontWeight: 600,
            color: "#162936",
            width: "100%",
          }}
        >
          {option.label}
          {option.icon ? <img src={option.icon} alt={option.label} /> : <></>}
        </MenuItem>
      ))}
    </Select>
  );
};
export default CustomDropdown;
