import { UserPayload } from '../../api/company';
import { useUpdateUserMutation } from '../../api/userList';
import useUserList from './useUserList';

const useUpdateUser = () => {
  const [updateUser, {isLoading}] = useUpdateUserMutation();
  const { getUserList } = useUserList();

  const editUser = async (payload: UserPayload) => {
    try {
      const response = await updateUser(payload).unwrap();

      if (response.data) {
        getUserList({});
      }
      return response;
    } catch (err) {
      console.error('Error while editing user :', err);
    }
  };

  return { editUser, isLoading };
};

export default useUpdateUser;
