import { FC } from 'react';
import InvestmentPerformance from './CustomDashboards/InvestmentPerformance';

interface CustomDashboardWrapperProps {
    data: {
        id: string;
        name?: string;
        embedId?: string;
        filterQuery?: string;
    } | null
};

const CustomDashboardWrapper: FC<CustomDashboardWrapperProps> = (props) => {
    const { data: dashboardData } = props;

    if (dashboardData?.name === 'Investment Performance') {
        return (<InvestmentPerformance id={dashboardData.id} />)
    }

    return (<></>);
}

export default CustomDashboardWrapper;
