import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import backgroundImage from '../../assets/images/pvx-home-bg.png';
import { NAV_ROUTES } from '../../constants/navRoutes';
import { genericTexts, titles } from '@utils/translations/en';
import UserAuth from '@services/auth';

const ErrorPage: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    UserAuth.clearTokens();
  }, []);

  return (
    <>
      <p
        style={{
          cursor: 'pointer',
          position: 'absolute',
          top: '50px',
          left: '50px',
          color: '#FFFFFF',
        }}
        onClick={() => navigate(NAV_ROUTES.HOME)}
      >
        {genericTexts.goToHome}
      </p>
      <Box
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#FFFFFF',
          textAlign: 'center'
        }}
      >
        <div>
          <h1>{titles.SOMETHING_WENT_WRONG}</h1>
          <p>{genericTexts.pleaseWaitWhileWeFixIt}</p>
        </div>
      </Box>
    </>
  );
}

export default ErrorPage;
