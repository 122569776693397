export const processRowDataWithMetadata = (name: string, metadata: any, data: any) => {
    try {
        if (!metadata.columns) {
            throw new Error("Invalid metadata format: 'columns' key not found.");
        }

        const columns = metadata.columns.map((column: any) => ({
            key: column.id,
            label: column.name
        }));

        const mappedData = data.map((entry: any, rowIndex: number) => {
            const mappedEntry: any = {};
            columns.forEach((col: any, index: number) => {
                mappedEntry[col.key] = entry[index];
            });
            mappedEntry.id = `${name}-${rowIndex}`;
            return mappedEntry;
        });

        return { mappedData, mappedColumns: columns };

    } catch (error) {
        console.error("Error parsing metadata");
        return { mappedData: [], mappedColumns: [] };
    }
};

export const processNestedRowDataWithMetadata = (
    name: string,
    metadata: any,
    data: any,
    combinedColumns: string[]
) => {
    try {
        if (!metadata.columns) {
            throw new Error("Invalid metadata format: 'columns' key not found.");
        }

        // Determine the last column in combinedColumns for merging
        const lastColumnKey = combinedColumns[combinedColumns.length - 1];

        if (!lastColumnKey) {
            throw new Error("Invalid combinedColumns: At least one column must be specified.");
        }

        // Prepare metadata by removing combinedColumns except for the last column
        const columns = metadata.columns
            .filter((col: any) => !combinedColumns.includes(col.id) || col.id === lastColumnKey)
            .map((col: any) => ({ key: col.id, label: col.name }));

        let idCounter = 0;

        function processNode(key: any, value: any) {
            const node: any = {
                id: `${name}-${idCounter++}`,
            };

            // Determine the merged value for the last column key
            let mergedValue = "All";
            for (let i = combinedColumns.length - 1; i >= 0; i--) {
                const columnKey = combinedColumns[i];
                if (value[columnKey] !== undefined && value[columnKey] !== null && value[columnKey] !== "") {
                    mergedValue = value[columnKey];
                    break;
                }
            }
            node[lastColumnKey] = mergedValue;

            // Add other columns from metadata
            columns.forEach((column: any) => {
                if (column.key !== lastColumnKey) {
                    node[column.key] = value[column.key] !== undefined ? value[column.key] : '-';
                }
            });

            if (value.children && Object.keys(value.children).length > 0) {
                node.children = Object.entries(value.children).map(([childKey, childValue]) =>
                    processNode(childKey, childValue)
                );
            }

            return node;
        }

        const mappedData = Object.entries(data).map(([key, value]) => processNode(key, value));

        return { mappedData, mappedColumns: columns };
    } catch (error) {
        console.error("Error processing data", error);
        return { mappedData: [], mappedColumns: [] };
    }
};

export const processPivotDataWithMetadata = (
    name: string,
    metadata: any,
    columns: any,
    data: any
) => {
    // Map metadata placements to their respective column indices
    const columnPlacements = metadata.columns.reduce((acc: Record<string, any>, col: any) => {
        const columnIndex = columns.indexOf(col.id);
        if (columnIndex >= 0) {
            acc[col.placement] = { id: col.id, index: columnIndex, name: col.name || col.id };
        }
        return acc;
    }, {});

    const xColumn = columnPlacements["X"];
    const yColumn = columnPlacements["Y"];
    if (!xColumn || !yColumn) {
        console.error("Invalid metadata configuration: X or Y column missing", metadata);
        return { mappedData: [], mappedColumns: [] };
    }

    // Determine the value column by excluding X and Y placements
    const valueColumn = metadata.columns
        .filter((col: any) => col.placement !== "X" && col.placement !== "Y")[0];

    if (!valueColumn) {
        console.error("Invalid metadata configuration: Value column missing", metadata);
        return { mappedData: [], mappedColumns: [] };
    }

    // Extract unique Y-column values for table columns
    const yValueData = Array.from(
        new Set(data.map((row: any) => row[yColumn.index]))
    );

    // Process data into rows based on X-column grouping
    const rows = data.reduce((acc: any, row: any[]) => {
        const xValue = row[xColumn.index];
        const yValue = row[yColumn.index];
        const value = row[2];

        if (!acc[xValue]) {
            acc[xValue] = { [xColumn.id]: xValue };
        }

        acc[xValue][yValue] = !isNaN(value) && value !== null && value !== '' ? Number(value) : value;

        return acc;
    }, {} as Record<string, Record<string, number | string>>);

    const tableRows = Object.values(rows);

    // Build the column headers for the table
    const tableColumns = [
        { key: xColumn.id, label: xColumn.name },
        ...yValueData
            .map((data) => ({
                key: data,
                label: data,
            })),
    ];

    return { mappedData: tableRows, mappedColumns: tableColumns };
};

