import { FC, useEffect } from 'react';
import { Box } from '@mui/material';
import backgroundImage from '../../assets/images/pvx-home-bg.png';
import useAuth from '../../hooks/useAuth';
import { genericTexts } from '@utils/translations/en';

const AuthRedirect: FC = () => {
    const { loginUrl } = useAuth();

    // Hook to redirect to keycloak login page
    useEffect(() => {
        window.location.href = loginUrl;
    }, []);

    return (
        <>
            <Box
                sx={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#FFFFFF',
                    textAlign: 'center'
                }}
            >
                <div>
                    <p>{genericTexts.authenticating}</p>
                </div>
            </Box>
        </>
    );
}

export default AuthRedirect;
