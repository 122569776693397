import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, useMediaQuery } from "@mui/material";
import pvxLogo from "../../../assets/icons/pvx-logo-full.svg";
import theme from "../../../theme";
import { ReactComponent as Chevron } from "../../../assets/icons/chevron-down.svg";
import { NAV_ROUTES } from "@constants/navRoutes";

const TopBar: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [showMenuPanel, setShowMenuPanel] = useState<boolean>(false);

  const MenuOptions = [
    { id: "funding", label: "Funding" },
    { id: "company", label: "Company" },
    { id: "resources", label: "Resources" },
    { id: "insights", label: "Insights" },
  ];

  return (
    <>
      <Box
        sx={{
          width: "100%",
          background: "#3D4C56",
          color: "white",
          borderRadius: "28px",
          display: "flex",
          justifyContent: "space-between",
          border: "1px solid",
          borderImageSource:
            "linear-gradient(268.86deg, rgba(98, 99, 103) 35.97%, rgba(98, 99, 103) 72.48%)",
          backdropFilter: "blur(100px)",
          padding: "12px 16px",
          zIndex: 20,
        }}
      >
        <a href="https://pvxpartners.com">
          <img
            src={pvxLogo}
            alt="PvXLogo"
            style={{ width: isMobile ? '180px' : '223px' }}
          />
        </a>
        <Box sx={{ display: isMobile ? "none" : "flex", gap: "16px" }}>
          {/* {MenuOptions.map((option) => (
            <Button
              id={option.id}
              sx={{
                color: "#F6F8F9CC",
                fontSize: "15px",
                lineHeight: "15px",
                letterSpacing: "-0.5px",
                textTransform: "none",
              }}
            >
              {option.label}
            </Button>
          ))} */}
        </Box>
        <Box sx={{ display: isMobile ? "none" : "flex", gap: "10px" }}>
          <Button
            sx={{
              background: "#F6F8F91A",
              borderRadius: "100px",
              padding: "14px 24px",
              color: "#F6F8F9",
              letterSpacing: "-3%",
              fontSize: "15px",
              lineHeight: "15px",
              fontWeight: 600,
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#F6F8F91A",
              },
            }}
            onClick={() => navigate(NAV_ROUTES.AUTH)}
          >
            Sign In
          </Button>
          {/* <Button
            sx={{
              background: "#F6F8F9",
              borderRadius: "100px",
              padding: "14px 24px",
              color: "#101112",
              letterSpacing: "-3%",
              fontSize: "15px",
              lineHeight: "15px",
              fontWeight: 600,
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#F6F8F9",
              },
            }}
          >
            Get Terms
          </Button> */}
        </Box>
        <Button
          onClick={() => {
            setShowMenuPanel((current) => !current);
          }}
          sx={{
            display: isMobile ? "inline-block" : "none",
            background: "#F6F8F9",
            borderRadius: "100px",
            padding: "8px 12px",
            color: "#101112",
            letterSpacing: "-3%",
            fontSize: "15px",
            lineHeight: "15px",
            fontWeight: 600,
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#F6F8F9",
            },
          }}
        >
          {showMenuPanel ? "Close" : "Menu"}
        </Button>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: showMenuPanel && isMobile ? 0 : "-100%",
          height: "fit-content",
          width: "100%",
          zIndex: "10",
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          backdropFilter: "blur(10px)",
          borderBottomLeftRadius: "24px",
          borderBottomRightRadius: "24px",
          padding: "24px",
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
          border: "1px solid rgba(255, 255, 255, 0.2)",
          transition: "top 0.3s ease-in-out",
        }}
      >
        <Box
          sx={{
            marginTop: "72px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {/* {MenuOptions.map((option) => (
            <Button
              id={option.id}
              sx={{
                paddingY: "12px",
                color: "#356661",
                fontSize: "18px",
                lineHeight: "18px",
                letterSpacing: "-0.5px",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {option.label}
              <Chevron style={{ rotate: "270deg" }} />
            </Button>
          ))} */}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            marginTop: "24px",
          }}
        >
          <Button
            sx={{
              background: "#F6F8F9",
              borderRadius: "100px",
              padding: "14px 24px",
              color: "#101112",
              letterSpacing: "-0.5px",
              fontSize: "18px",
              lineHeight: "18px",
              fontWeight: 600,
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#F6F8F9",
              },
            }}
            onClick={() => navigate(NAV_ROUTES.AUTH)}
          >
            Sign In
          </Button>
          {/* <Button
            sx={{
              background: "#101112",
              borderRadius: "100px",
              padding: "14px 24px",
              color: "white",
              letterSpacing: "-0.5px",
              fontSize: "18px",
              lineHeight: "18px",
              fontWeight: 600,
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#101112",
              },
            }}
          >
            Get Terms
          </Button> */}
        </Box>
      </Box>
    </>
  );
};

export default TopBar;
