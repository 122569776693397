import { FC } from 'react';
import { Box, Typography, useTheme, useMediaQuery, Button } from '@mui/material';
import headerBackgroundImage from "@assets/images/header-bg.png";
import headerBackgroundLogoImage from "@assets/images/funding-header-logo.png";
import CalanderIcon from '@assets/icons/funding-calander-icon.svg';
import { ReactComponent as ArrowIcon } from '@assets/images/dotted-arrow.svg';
import { labels } from '@utils/translations/en';

interface CalculatorHeaderProps {
    view: number;
    onClickSimulateFacilitySize?: () => void;
};

const CalculatorHeader: FC<CalculatorHeaderProps> = ({ view, onClickSimulateFacilitySize }) => {
    const theme = useTheme();
    const isMediumDevice = useMediaQuery(theme.breakpoints.down('lg'));
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            id="calculator_landing_header"
            sx={{
                fontFamily: "Manrope, sans-serif",
                width: "calc(100% - 1.25rem)",
                height: isSmallDevice ? "max-content" : "40.625rem",
                m: "0.75rem"
            }}
        >
            <Box
                sx={{
                    height: isSmallDevice ? "max-content" : "40.625rem",
                    background: "#000000",
                    backgroundImage: `url(${headerBackgroundImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: 'center',
                    // borderRadius: "0 0 1.5rem 1.5rem",
                    borderRadius: "1.5rem",
                    overflow: "hidden",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: isSmallDevice ? "column" : "row",
                        alignContent: "center"
                    }}
                >
                    <Box
                        sx={{
                            height: "100%",
                            width: isSmallDevice ? "100%" : "60%",
                        }}
                    >
                        <Box
                            sx={{
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "flex-start",
                                px: isSmallDevice ? "1rem" : "3rem"
                            }}
                        >
                            <Box
                                sx={{
                                    width: isSmallDevice ? "100%" : "20rem",
                                    height: "max-content",
                                    px: "1rem",
                                    py: "1rem",
                                    border: "0.1px solid #FFFFFF10",
                                    mt: "1rem",
                                    mb: "1rem",
                                    display: "flex",
                                    justifyContent: isSmallDevice ? "flex-start" : "center",
                                    alignItems: "center",
                                    borderRadius: "3rem",
                                    background: "#E6FFF810",
                                    position: "relative",
                                    "&::after": {
                                        content: '""',
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        zIndex: -1,
                                        borderRadius: "inherit",
                                        padding: "1px",
                                        background: "linear-gradient(to top right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(110, 119, 119, 1))",
                                        WebkitMask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                                        mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                                        maskComposite: "exclude",
                                        WebkitMaskComposite: "xor",
                                    },
                                }}
                            >
                                <img
                                    src={headerBackgroundLogoImage}
                                    alt={"PvX Logo"}
                                    style={{
                                        width: isMediumDevice ? "8rem" : "15rem",
                                        height: 'auto',
                                    }}
                                />
                            </Box>
                            <Typography
                                sx={{
                                    fontFamily: "Manrope, sans-serif",
                                    fontSize: isMediumDevice ? (isSmallDevice ? "2.5rem" : "3rem") : "3.5rem",
                                    fontWeight: 500,
                                    color: "white",
                                    lineHeight: isMediumDevice ? (isSmallDevice ? "2.5rem" : "3rem") : "3.5rem",
                                    letterSpacing: "-0.4px",
                                    mt: "1rem",
                                    mb: "2rem",
                                }}
                            >
                                <span style={{ color: "#B1B5B7" }}>Simulate your facility size</span> and indicative cash cost using our Funding Calculator
                            </Typography>
                            <Button
                                sx={{
                                    marginTop: isSmallDevice ? 0 : "1rem",
                                    marginBottom: isSmallDevice ? "2rem" : 0,
                                    maxWidth: "15rem",
                                    background: "#F6F8F9",
                                    borderRadius: "100px",
                                    padding: "14px 24px",
                                    color: "#101112",
                                    letterSpacing: "-3%",
                                    fontSize: isSmallDevice ? "0.8rem" : "1rem",
                                    lineHeight: "15px",
                                    fontWeight: 600,
                                    textTransform: "none",
                                    fontFamily: "Manrope, sans-serif",
                                    "&:hover": {
                                        backgroundColor: "#F6F8F9",
                                    },
                                }}
                                onClick={onClickSimulateFacilitySize}
                            >
                                SIMULATE FACILITY SIZE
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            height: "100%",
                            width: isSmallDevice ? "100%" : "40%",
                            my: isSmallDevice ? "1rem" : 0,
                        }}
                    >
                        <Box
                            sx={{
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                px: isSmallDevice ? "1rem" : "3rem"
                            }}
                        >
                            <Box
                                sx={{
                                    width: isMediumDevice ? (isSmallDevice ? "100%" : "25rem") : "25rem",
                                    height: isSmallDevice ? "max-content" : "65%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    p: isSmallDevice ? "1rem" : "3rem",
                                    marginBottom: isSmallDevice ? "1rem" : 0,
                                    background: isSmallDevice ? "linear-gradient(to top left, #00000099, #24464540)" : "linear-gradient(to top right, #00000099, #24464540)",
                                    borderRadius: isSmallDevice ? "1rem" : "2rem 2rem 0 0",
                                    position: "relative",
                                    "::before": {
                                        content: '""',
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        borderRadius: isSmallDevice ? "1rem" : "2rem 2rem 0 0",
                                        padding: "1px",
                                        background: isMediumDevice ?
                                            "linear-gradient(298.03deg, rgba(110, 221, 175, 0.0851) 9.96%, rgba(136, 227, 221, 0.2553) 30.76%, rgba(131, 245, 198, 0.264311) 43.78%, rgba(136, 227, 221, 0.37) 63.72%),linear-gradient(349deg, rgba(110, 221, 175, 0) 73.68%, rgba(232, 235, 255, 0.7) 93.18%)"
                                            : "linear-gradient(118.03deg, rgba(110, 221, 175, 0.0851) 9.96%, rgba(136, 227, 221, 0.2553) 30.76%, rgba(131, 245, 198, 0.264311) 43.78%, rgba(136, 227, 221, 0.37) 63.72%), linear-gradient(29deg, rgba(110, 221, 175, 0) 73.68%, rgba(232, 235, 255, 0.7) 93.18%)",
                                        WebkitMask: isMediumDevice ? "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)" : "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                                        maskComposite: "exclude",
                                    },
                                }}
                            >
                                <img
                                    src={CalanderIcon}
                                    alt={"book now icon"}
                                    style={{
                                        width: isSmallDevice ? "4rem" : "5rem",
                                        height: 'auto',
                                        marginBottom: isSmallDevice ? 0 : "1rem",
                                        marginLeft: "-0.75rem"
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontFamily: "Manrope, sans-serif",
                                        fontSize: isSmallDevice ? "1rem" : "2rem",
                                        fontWeight: 500,
                                        color: "white",
                                        lineHeight: isSmallDevice ? "1rem" : "2rem",
                                        letterSpacing: "-0.4px",
                                    }}
                                >
                                    <span style={{ color: "#B1B5B7" }}>Book a</span> consultation with a PvX representative
                                </Typography>
                                <Button
                                    variant="contained"
                                    sx={{
                                        marginTop: isSmallDevice ? "1rem" : "3rem",
                                        background: "#7FBCAD",
                                        color: theme.palette.common.white,
                                        borderRadius: '1rem',
                                        textTransform: 'none',
                                        width: '100%',
                                        px: '2rem',
                                        py: "1rem",
                                    }}
                                    href="https://pvxpartners.pipedrive.com/scheduler/KkdPWGCK/jeff-cohen-pvx-partners"
                                    target="_blank"
                                >
                                    <>
                                        <Typography
                                            variant="body2"
                                            fontWeight={550}
                                            fontSize='1rem'
                                            fontFamily="Manrope, sans-serif"
                                            color="#F6F8F9"
                                        >
                                            {labels.fundingCalculatorPage.bookNow}
                                        </Typography>
                                        <ArrowIcon style={{ height: "1rem" }} />
                                    </>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default CalculatorHeader;
