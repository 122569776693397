import { useLazyGetCustomDashboardFiltersQuery } from '../../api/userModules';

const useCustomDashboardFilters = () => {
    const [getCustomDashboardFilters, { isLoading, isError }] = useLazyGetCustomDashboardFiltersQuery();

    const getDashboardFilterValues = async (submoduleId: string) => {
        try {
            const response = await getCustomDashboardFilters(submoduleId).unwrap();
            if (response?.data?.result) {
                return response.data.result;
            }
        } catch (err) {
            console.error(err);
        }
    };

    return { getDashboardFilterValues, isLoading, isError };
};

export default useCustomDashboardFilters;
