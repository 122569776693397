import React, { ReactNode, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export type Column<T> = {
  key: keyof T;
  label: string;
  render?: (value: T[keyof T], row: T) => ReactNode;
};

export type Row<T> = T & {
  id: string;
  children?: Row<T>[];
};

export type GenericTableProps<T> = {
  data: Row<T>[];
  columns: Column<T>[];
  meta: any;
  onRowExpand?: (row: Row<T>, isExpanded: boolean) => void;
};

const ROW_COLORS = ["#F0F0F1", "#D8DBDD", "#FFFFFF"];

function GenericTable<T>({
  data,
  columns,
  meta,
  onRowExpand,
}: GenericTableProps<T>) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
  const [allExpanded, setAllExpanded] = useState(false);

  useEffect(() => {
    const allExpanded = areAllRowsExpanded(data);
    setAllExpanded(allExpanded);
  }, [expandedRows, data]);

  const columnMetadata = meta?.columns?.length
    ? meta.columns.reduce((obj: any, item: any) => {
      obj[item.id] = item;
      return obj;
    }, {})
    : [];

  const toggleAllRows = () => {
    const newExpandState = !allExpanded;
    setAllExpanded(newExpandState);

    const updatedExpandedRows: Record<string, boolean> = {};
    const setAllRowState = (rows: Row<T>[]) => {
      rows.forEach((row) => {
        updatedExpandedRows[row.id] = newExpandState;
        if (row.children) {
          setAllRowState(row.children);
        }
      });
    };
    setAllRowState(data);

    setExpandedRows(updatedExpandedRows);

    if (onRowExpand) {
      const updateRowsState = (rows: Row<T>[], isExpanded: boolean) => {
        rows.forEach((row) => {
          onRowExpand(row, isExpanded);
          if (row.children) {
            updateRowsState(row.children, isExpanded);
          }
        });
      };
      updateRowsState(data, newExpandState);
    }
  };

  const toggleRow = (id: string) => {
    setExpandedRows((prev) => {
      const isExpanded = !prev[id];
      if (onRowExpand) {
        const row = findRowById(data, id);
        if (row) onRowExpand(row, isExpanded);
      }
      return { ...prev, [id]: isExpanded };
    });
  };

  const findRowById = (rows: Row<T>[], id: string): Row<T> | undefined => {
    for (const row of rows) {
      if (row.id === id) return row;
      if (row.children) {
        const found = findRowById(row.children, id);
        if (found) return found;
      }
    }
    return undefined;
  };

  const areAllRowsExpanded = (rows: Row<T>[]): boolean => {
    for (const row of rows) {
      if (row.children) {
        if (!expandedRows[row.id] || !areAllRowsExpanded(row.children))
          return false;
      }
    }
    return true;
  };

  const formatCurrency = (value: any) => {
    const formattedValue = Math.round(Math.abs(Number(value))).toLocaleString(
      "en-US"
    );
    return value < 0 ? `- $${formattedValue}` : `$${formattedValue}`;
  };

  const checkCellValueType = (type: string, value: any) => {
    switch (type) {
      case "string":
        return value;
      case "percentage":
        return Number(value) === 0
          ? "-"
          : `${Math.round(Number(value) * 100)}%`;
      case "currency":
        return Number(value) === 0 ? "-" : formatCurrency(value);
      case "date":
        return value;
      default:
        return null;
    }
  };

  const getCellValueAllignment = (type: string) => {
    switch (type) {
      case "percentage":
      case "currency":
        return "right";
      case "string":
      case "date":
        return "left";
      default:
        return "center";
    }
  };

  const renderCellContent = (value: T[keyof T], key: any): ReactNode => {
    const columnType = columnMetadata[key]?.type;
    if (
      typeof value === "string" ||
      typeof value === "number" ||
      typeof value === "boolean"
    ) {
      let renderValue = checkCellValueType(columnType, value);

      // Handle pivot table columns where type needs to be inferred from metadata
      if (!renderValue && meta.chartType === "PIVOT_TABLE") {
        const pivotColumnType = meta.columns?.[2]?.type;
        if (pivotColumnType) {
          renderValue = checkCellValueType(pivotColumnType, value);
        }
      }

      const icons = columnMetadata[key]?.icons;
      let leftIcon = null;
      let rightIcon = null;

      if (icons && Array.isArray(icons)) {
        icons.forEach((icon) => {
          if (
            icon.type === "conditional" &&
            icon.condition &&
            new Function("value", `return ${icon.condition};`)(value)
          ) {
            if (icon.position === "left") {
              leftIcon = (
                <img
                  src={require(`../../assets/icons/${icon.location}`)}
                  alt="left_icon"
                  style={{ marginRight: "8px", userSelect: "none" }}
                />
              );
            } else if (icon.position === "right") {
              rightIcon = (
                <img
                  src={require(`../../assets/icons/${icon.location}`)}
                  alt="right_icon"
                  style={{ marginLeft: "8px", userSelect: "none" }}
                />
              );
            }
          }
        });
      }

      if (value === "null") {
        return (
          <span
            style={{
              width: "100%",
              minWidth: "3rem",
              display: "flex",
              justifyContent: getCellValueAllignment(columnType),
            }}
          >
            N/A
          </span>
        );
      }

      return (
        <span
          style={{
            width: "100%",
            minWidth: columnType === "currency" ? "6rem" : "3rem",
            display: "flex",
            justifyContent: getCellValueAllignment(columnType),
          }}
        >
          {leftIcon}
          {renderValue ?? value}
          {rightIcon}
        </span>
      );
    }

    return (
      <span
        style={{
          width: "100%",
          minWidth: "3rem",
          display: "flex",
          justifyContent: getCellValueAllignment(columnType),
        }}
      >
        -
      </span>
    );
  };

  const getRowCellBackGround = (index: number) =>
    ROW_COLORS[index % ROW_COLORS.length];

  const renderRows = (rows: Row<T>[], level = 0): ReactNode => {
    return rows.map((row, rowIndex) => {
      const isExpanded = expandedRows[row.id];
      return (
        <React.Fragment key={`${row.id}_${rowIndex}`}>
          <TableRow style={{ backgroundColor: "#F0F0F1" }}>
            {meta.chartType === "MULTI_LEVEL_TABLE" && (
              <TableCell
                style={{
                  width: "5px",
                  paddingLeft: `${level * 20 + 10}px`,
                  paddingTop: 0,
                  paddingBottom: 0,
                  backgroundColor: "white",
                  borderBottom: "none",
                  lineHeight: "1rem",
                  paddingRight: '0px'
                }}
              >
                {row.children && (
                  <button
                    onClick={() => toggleRow(row.id)}
                    style={{
                      border: "none",
                      background: "none",
                      cursor: "pointer",
                      padding: 0,
                    }}
                  >
                    <ArrowDropDownIcon
                      style={{
                        color: "#162C36",
                        rotate: isExpanded ? "0deg" : "-90deg",
                      }}
                    />
                  </button>
                )}
              </TableCell>
            )}
            {columns.map((column, colindex) => (
              <TableCell
                key={String(column.key)}
                sx={{
                  paddingY: "0.75rem",
                  paddingLeft: "0.5rem",
                  fontSize: "0.875rem",
                  fontWeight: 500,
                  lineHeight: "1rem",
                  color: "#1B1C17",
                  border: "none",
                  borderLeft:
                    colindex === 0 || level % 3 === 2  //TODO: Change hardcoded values in future scope
                      ? "none"
                      : "1px solid #2F736E1F",
                  background: getRowCellBackGround(level),
                  borderTopLeftRadius: colindex === 0 ? "8px" : "0px",
                  borderBottomLeftRadius: colindex === 0 ? "8px" : "0px",
                  borderTopRightRadius:
                    colindex === columns.length - 1 ? "8px" : "0px",
                  borderBottomRightRadius:
                    colindex === columns.length - 1 ? "8px" : "0px",
                }}
              >
                {column.render
                  ? column.render(row[column.key], row)
                  : renderCellContent(row[column.key], column.key)}
              </TableCell>
            ))}
          </TableRow>
          {isExpanded && row.children && renderRows(row.children, level + 1)}
        </React.Fragment>
      );
    });
  };

  if (!data.length) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "90%",
            height: "10rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#80808050",
          }}
        >
          <p>No data</p>
        </div>
      </div>
    );
  }

  return (
    <TableContainer
      style={{
        width: "100%",
        height: "100%",
        marginBottom: "12px",
      }}
    >
      <Table
        sx={{
          borderSpacing: "0 10px",
          borderCollapse: "separate",
        }}
      >
        <TableHead>
          <TableRow sx={{ borderRadius: "20px" }}>
            {meta.chartType === "MULTI_LEVEL_TABLE" && (
              <TableCell
                sx={{
                  padding: 0,
                  paddingLeft: "5px",
                  fontSize: "0.75rem",
                  fontWeight: 700,
                  lineHeight: "1rem",
                  borderBottom: "none",
                  background: "#2F736E",
                  borderTopLeftRadius: "8px",
                  WebkitBorderBottomLeftRadius: "8px",
                  paddingRight: '1rem'
                }}
              >
                <button
                  onClick={toggleAllRows}
                  style={{
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                    fontWeight: "bold",
                    color: "#6C6C6C",
                  }}
                >
                  <ArrowDropDownIcon
                    style={{
                      color: "#9AD6D1",
                      rotate: allExpanded ? "0deg" : "-90deg",
                    }}
                  />
                </button>
              </TableCell>
            )}
            {columns.map((column, i) => (
              <TableCell
                key={String(column.key)}
                sx={{
                  paddingY: "0.75rem",
                  paddingLeft: "0.5rem",
                  fontSize: "0.75rem",
                  fontWeight: 700,
                  lineHeight: "1rem",
                  color: "#F6F8F9",
                  borderLeft: "1px solid #737F86",
                  borderBottom: "none",
                  background: "#2F736E",
                  borderTopRightRadius:
                    i === columns.length - 1 ? "8px" : "0px",
                  borderBottomRightRadius:
                    i === columns.length - 1 ? "8px" : "0px",
                  borderTopLeftRadius:
                    meta.chartType !== "MULTI_LEVEL_TABLE" && i === 0
                      ? "8px"
                      : "0px",
                  WebkitBorderBottomLeftRadius:
                    meta.chartType !== "MULTI_LEVEL_TABLE" && i === 0
                      ? "8px"
                      : "0px",
                  textAlign: getCellValueAllignment(
                    columnMetadata[column.key]?.type
                  ),
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{renderRows(data)}</TableBody>
      </Table>
    </TableContainer>
  );
}

export default GenericTable;
