import { FC, useEffect, useState } from "react";
import { FullPageLoader, GenericTable } from "@components";
import {
    processNestedRowDataWithMetadata,
    processPivotDataWithMetadata,
    processRowDataWithMetadata,
} from "../../../components/GenericTable/tableDataUtil";
import useChartById from "@hooks/api/useChartById";
import useCharts from "@hooks/api/useCharts";
import { Box, Typography, Tabs, Tab, useMediaQuery, useTheme } from "@mui/material";
import InvestmentPerformanceFilters from "../components/InvestmentPerformanceFilters";
import Pagination from "../components/Pagination";

enum INVESTMENT_PERFORMANCE_CHART_NAMES {
    InvestmentDetails = "Investment Details",
    Ledger = "Ledger",
    RevenueTrend = "Revenue Trend",
    IRRTrend = "IRR Trend",
    MOICTrend = "MOIC Trend",
    ExposureTrend = "Exposure Trend",
}

const PAGE_SIZE = 10;

const InvestmentPerformance: FC<{ id: string }> = ({ id }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [dashboardLoader, setDashboardLoader] = useState<boolean>(true);
    const [dashboardData, setDashboardData] = useState<
        { id: string; name: string; chartType: string, metadata: string }[]
    >([]);
    const [chartData, setChartData] = useState<
        { id: string; name: string; columns: any[]; rows: any[]; meta: any }[]
    >([]);
    const [selectedTab, setSelectedTab] = useState<string>(
        INVESTMENT_PERFORMANCE_CHART_NAMES.RevenueTrend
    );
    const [pageState, setPageState] = useState<Record<string, number>>({});
    const [filterSelection, setFilterSelection] = useState<Record<string, string>>({});

    const { getChartData } = useCharts();
    const { getChartDataById } = useChartById();

    // Hook to fetch dashboard data on load
    useEffect(() => {
        if (id) {
            getChartData(id).then((response) => {
                if (response) {
                    setDashboardData(response);
                }
            });
        }
    }, [id]);

    // Hook to fetch all chart data on load
    useEffect(() => {
        fetchDashboardData();
    }, [dashboardData, id, dashboardLoader]);

    // Hook to fetch all chart on filter update
    useEffect(() => {
        if (Object.keys(filterSelection).length > 0) {
            fetchDashboardData(filterSelection);
        }
    }, [filterSelection]);

    const fetchDashboardData = async (filters?: Record<string, string>) => {
        if (id && dashboardData?.length) {
            const chartsById: any[] = [];
            try {
                await Promise.all(
                    dashboardData.map(
                        async (chart: {
                            id: string;
                            name: string;
                            chartType: string;
                            metadata: string;
                        }) => {
                            const parsedMetadata = JSON.parse(chart.metadata);
                            const response = await getChartDataById(id, chart.id, filters, parsedMetadata?.sort?.fields, parsedMetadata?.sort?.order);
                            if (response) {
                                const { rows, columns, metadata } = formatChartResponseData(response);
                                chartsById.push({
                                    columns,
                                    rows,
                                    id: chart.id,
                                    name: response.name,
                                    meta: metadata,
                                });
                            }
                        }
                    )
                );

                setChartData(chartsById);
            } catch (error) {
                console.error("Error fetching chart data:", error);
            } finally {
                setDashboardLoader(false);
            }
        }
    };

    const formatChartResponseData = (response: any) => {
        let columns = [];
        let rows = [];
        const metadata = JSON.parse(response.metadata);
        if (metadata.chartType === "MULTI_LEVEL_TABLE") {
            const { mappedColumns, mappedData } =
                processNestedRowDataWithMetadata(
                    response.name,
                    metadata,
                    response.result.data,
                    ["investor", "game"]
                );
            columns = mappedColumns;
            rows = mappedData;
        } else if (metadata.chartType === "PIVOT_TABLE") {
            const { mappedColumns, mappedData } =
                processPivotDataWithMetadata(
                    response.name,
                    metadata,
                    response.result.columns,
                    response.result.data,
                );
            columns = mappedColumns;
            rows = mappedData;
        } else {
            const { mappedColumns, mappedData } =
                processRowDataWithMetadata(
                    response.name,
                    metadata,
                    response.result.data
                );
            columns = mappedColumns;
            rows = mappedData;
        }
        return { rows, columns, metadata };
    };

    const handlePageChange = (chartName: string, newPage: number) => {
        setPageState((prev) => ({ ...prev, [chartName]: newPage }));
    };

    const getChartByName = (name: string) => {
        const chart = chartData.find((chart) => chart.name === name);

        const currentPage = pageState[name] || 1;
        const paginatedRows = chart?.rows?.slice(
            (currentPage - 1) * PAGE_SIZE,
            currentPage * PAGE_SIZE
        ) || [];

        if (chart) {
            const isTabbedChart = [
                INVESTMENT_PERFORMANCE_CHART_NAMES.RevenueTrend,
                INVESTMENT_PERFORMANCE_CHART_NAMES.IRRTrend,
                INVESTMENT_PERFORMANCE_CHART_NAMES.MOICTrend,
                INVESTMENT_PERFORMANCE_CHART_NAMES.ExposureTrend
            ].includes(name as INVESTMENT_PERFORMANCE_CHART_NAMES);

            return (
                <Box
                    id={chart.name}
                    key={chart.name}
                    sx={{
                        width: "100%",
                        height: "fit-content",
                        backgroundColor: "white",
                        borderRadius: "12px",
                        padding: "0.75rem",
                        marginY: "1rem",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: isMobile ? "column" : "row",
                            justifyContent: "space-between",
                            marginBottom: "1rem",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "1rem",
                                lineHeight: "1.375rem",
                                fontWeight: 700,
                                color: "#162936",
                            }}
                        >
                            {isTabbedChart ? "Trend" : chart.name}
                        </Typography>
                        <Pagination
                            page={currentPage}
                            size={PAGE_SIZE}
                            total={chart.rows.length}
                            handleChange={(newPage) => handlePageChange(name, newPage)}
                            fileName={chart.name}
                            chartId={chart.id}
                            submoduleId={id}
                            filterSelection={filterSelection}
                        />
                    </Box>
                    {isTabbedChart ? (
                        <Tabs
                            value={selectedTab}
                            onChange={handleTabChange}
                            textColor="primary"
                            indicatorColor="primary"
                            sx={{
                                flexDirection: "column",
                                alignItems: "flex-start",
                                ".MuiTabs-indicator": {
                                    backgroundColor: "#51B8B0",
                                    height: "3px",
                                    borderRadius: "5px",
                                },
                            }}
                        >
                            <Tab
                                label="Recoup"
                                value={INVESTMENT_PERFORMANCE_CHART_NAMES.RevenueTrend}
                                sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: 700,
                                    textTransform: "none",
                                    color: "#6C6C6C",
                                    "&.Mui-selected": {
                                        color: "#51B8B0",
                                    },
                                    alignItems: "center",
                                }}
                            />
                            <Tab
                                label="IRR"
                                value={INVESTMENT_PERFORMANCE_CHART_NAMES.IRRTrend}
                                sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: 700,
                                    textTransform: "none",
                                    color: "#6C6C6C",
                                    "&.Mui-selected": {
                                        color: "#51B8B0",
                                    },
                                    alignItems: "center",
                                }}
                            />
                            <Tab
                                label="MOIC"
                                value={INVESTMENT_PERFORMANCE_CHART_NAMES.MOICTrend}
                                sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: 700,
                                    textTransform: "none",
                                    color: "#6C6C6C",
                                    "&.Mui-selected": {
                                        color: "#51B8B0",
                                    },
                                    alignItems: "center",
                                }}
                            />
                            <Tab
                                label="Exposure"
                                value={INVESTMENT_PERFORMANCE_CHART_NAMES.ExposureTrend}
                                sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: 700,
                                    textTransform: "none",
                                    color: "#6C6C6C",
                                    "&.Mui-selected": {
                                        color: "#51B8B0",
                                    },
                                    alignItems: "center",
                                }}
                            />
                        </Tabs>
                    ) : null}
                    <GenericTable data={paginatedRows} columns={chart.columns} meta={chart.meta} />
                </Box>
            );
        } else if (chart && !paginatedRows.length) {
            return (
                <Box
                    id={'no-data-chart'}
                    key={'no-data-chart'}
                    sx={{
                        width: "100%",
                        height: "fit-content",
                        backgroundColor: "white",
                        borderRadius: "12px",
                        padding: "0.75rem",
                        marginY: "1rem",
                    }}
                >
                    <GenericTable data={[]} columns={[]} meta={{}} />
                </Box>
            );
        } else {
            return null;
        }
    };

    const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue);
    };

    return (
        <>
            {dashboardLoader && <FullPageLoader hasSideNav={true} />}
            <div
                id="pvx-dashboard-container"
                style={{
                    width: "100%",
                    height: "100vh",
                    border: "none",
                    overflow: "auto",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                }}
            >
                <Typography
                    fontSize={"2rem"}
                    fontWeight={600}
                    sx={{ color: "#1B1C17", mt: "0.5rem", mb: "1.5rem" }}
                >
                    Investment Performance
                </Typography>
                <InvestmentPerformanceFilters id={id} onFilterChange={(selections) => setFilterSelection(selections)} />

                {getChartByName(INVESTMENT_PERFORMANCE_CHART_NAMES.InvestmentDetails)}
                {getChartByName(selectedTab)}
                {getChartByName(INVESTMENT_PERFORMANCE_CHART_NAMES.Ledger)}
            </div >
        </>
    );
};

export default InvestmentPerformance;
