import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import emptyDashboard from '../../assets/images/empty-dashboard.svg';
import { genericTexts, titles } from '@utils/translations/en';

const EmptyDashboard: FC = () => (
    <Box
        sx={{
            height: '100vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
        }}
    >
        <img
            src={emptyDashboard}
            alt={titles.PVX_PARTNERS}
            style={{
                width: '150px',
                height: 'auto',
                marginBottom: 20
            }}
        />
        <Typography
            sx={{
                fontSize: '1rem',
                fontWeight: '500',
                marginBottom: 1
            }}
        >
            {genericTexts.noDashboardAccess}
        </Typography>
        <span
            style={{
                fontSize: '0.8rem',
                color: '#6B7280',
                fontWeight: '400',
                maxWidth: '400px'
            }}
        >
            {genericTexts.reachOutAdmin1}
            <a href="mailto:admin@abc.com" style={{ color: '#1D4ED8' }}>
                {genericTexts.administrator}
            </a>
            {genericTexts.reachOutAdmin2}
        </span>
    </Box>
);

export default EmptyDashboard;
