import React from "react";
import { Box } from "@mui/material";
import { ReactComponent as DollarIcon } from '../../../assets/icons/dollar-icon.svg';


interface ChartInfoChipProps {
  info: string;
}

const ChartInfoChip: React.FC<ChartInfoChipProps> = ({ info }) => (
  <Box
    sx={{
      display: 'flex',
      gap: '6px',
      alignItems: 'center',
      fontFamily:"Manrope, sans-serif",
      paddingLeft: "8px",
      paddingRight: "12px",
      paddingY: "8px",
      mb: "1rem",
      background: "#FFFFFF",
      borderRadius: "32px",
      border: "1px solid #E8EAEB",
      color: "#16293699",
      fontWeight: "800",
      fontSize: "12px",
      boxShadow: `0px 0.86px 2.21px 0px #E8EAEB59,
      0px 2.06px 5.32px 0px #E8EAEB40,
      0px 3.88px 10.02px 0px #E8EAEB35,
      0px 6.92px 17.87px 0px #E8EAEB2D,
      0px 12.95px 33.42px 0px #E8EAEB24,
      0px 31px 80px 0px #E8EAEB19`,
    }}
  >
    <DollarIcon />
    {info}
  </Box>
);

export default ChartInfoChip;
