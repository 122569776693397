import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box, useTheme, useMediaQuery, Skeleton } from '@mui/material';
import infoIconQuestion from '../../../assets/images/info_icon_question.svg';
import { RootState } from '../../../stores/store';
import { formatCurrency, percentageDifference } from '../../../utils/common';
import { labels, messages } from '@utils/translations/en';
import { ReactComponent as IncreaseIcon } from '../../../assets/icons/increase-icon.svg';
import { CustomTooltip, Odometer } from '@components';

interface FundingCalculatorSummaryProps {
    showLoader: boolean;
    cashBalanceIncrease: string;
}

const FundingCalculatorSummary: FC<FundingCalculatorSummaryProps> = ({ showLoader, cashBalanceIncrease }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const fundingCalculatorData = useSelector(
        (state: RootState) => state?.FundingCalculatorData
    );

    const { cashCost, eligibleFund, annualFacilitySize } = fundingCalculatorData.fundingCalculations.summary || {};
    const profitBeforeTaxesDatasets = fundingCalculatorData.fundingCalculations.charts.cumulative_profit_before_taxes.datasets

    const finalIndex = profitBeforeTaxesDatasets?.[0]?.data?.length - 1;
    const investmentProfit = profitBeforeTaxesDatasets?.[0]?.data?.[finalIndex].y || 0
    const nonInvestmentProfit = profitBeforeTaxesDatasets?.[1]?.data?.[finalIndex].y || 0
    const profitPercentageDelta = Number(Math.round(percentageDifference(investmentProfit, nonInvestmentProfit))).toFixed(0);
    const paybackPeriod = fundingCalculatorData.fundingCalculations.variables.paybackPeriodInMonths;

    const getCashCostPercentageRange = () => {
        const start = Math.floor(cashCost * 100);
        const end = Math.ceil(cashCost * 100);
        if (start === end) {
            return `${(start - 1).toFixed(0)}% - ${start.toFixed(0)}%`;
        } else {
            return `${start.toFixed(0)}% - ${end.toFixed(0)}%`;
        }
    };

    const getCashCostRange = () => {
        const start = Math.floor(cashCost * 100);
        const end = Math.ceil(cashCost * 100);
        if (start === end) {
            return `${formatCurrency(start * 10000)}`;
        } else {
            return `${formatCurrency(start * 10000)} - ${formatCurrency(end * 10000)}`;
        }
    };

    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: "wrap",
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: "1rem"
            }}
        >
            <Box
                sx={{
                    background: '#F2F6F7',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: "7rem",
                    borderRadius: "1.25rem",
                    p: "1.25rem",
                    justifyContent: 'space-between',
                    alignItems: isSmallMobile ? "center" : "space-between"
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        height: "max-contnet",
                        display: 'flex',
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <Typography
                        variant="body2"
                        fontWeight={800}
                        fontSize='1rem'
                        fontFamily="Manrope, sans-serif"
                        color="#898C8D"
                        letterSpacing='-0.6px'
                    >
                        {`Est. ${labels.fundingCalculatorPage.cashCost}`}
                    </Typography>
                    <CustomTooltip
                        content={
                            <>
                                <div style={{ fontFamily: "Manrope, sans-serif", fontWeight: 800, fontSize: "0.65rem" }}>
                                    <b>{labels.fundingCalculatorPage.cashCost.toUpperCase()}</b>
                                </div>
                                <br />
                                <div style={{ fontFamily: "Manrope, sans-serif", fontWeight: 550, fontSize: "0.8rem" }}>
                                    <b>{labels.fundingCalculatorPage.cashCost}</b>{messages.fundingCalculatorPage.cashCostTooltip}
                                    <br />
                                    <br />
                                    As an example, if PvX funds $1,000,000 of UA spends, the cash cost would be {getCashCostRange()}.
                                </div>
                            </>
                        }
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                width: isMobile ? "1.5rem" : "2rem",
                                height: isMobile ? "1.5rem" : "2rem",
                                border: `1px solid #989CA270`,
                                borderRadius: "5rem", background: theme.palette.common.white
                            }}
                        >
                            <img src={infoIconQuestion} alt="Info Icon" style={{ width: isMobile ? "0.8rem" : "1rem", height: 'auto' }} />
                        </div>
                    </CustomTooltip>
                </Box>
                {(cashCost) ? (
                    <Typography
                        color="#162936"
                        fontFamily="Manrope, sans-serif"
                        fontSize="1.5rem"
                        fontWeight={800}
                        letterSpacing={"-0.72px"}
                    >
                        <Odometer
                            value={getCashCostPercentageRange()}
                            loading={showLoader}
                        />
                    </Typography>
                ) : (
                    <Skeleton
                        variant="rectangular"
                        width="80%"
                        height="2rem"
                        sx={{ borderRadius: '0.5rem', my: '0.5rem', background: 'linear-gradient(to right, #DCEBE7, #F2F6F7)' }}
                    />
                )}
            </Box>
            <Box
                sx={{
                    background: '#F2F6F7',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: "7rem",
                    borderRadius: "1.25rem",
                    p: "1.25rem",
                    justifyContent: "space-between",
                    alignItems: isSmallMobile ? "center" : "space-between"
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        height: "max-contnet",
                        display: 'flex',
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <Typography
                        variant="body2"
                        fontWeight={800}
                        fontSize='1rem'
                        fontFamily="Manrope, sans-serif"
                        color="#898C8D"
                        letterSpacing='-0.6px'
                    >
                        {`Est. ${labels.fundingCalculatorPage.increaseInProfits}`}
                    </Typography>
                    <CustomTooltip
                        content={
                            <>
                                <div style={{ fontFamily: "Manrope, sans-serif", fontWeight: 800, fontSize: "0.65rem" }}>
                                    <b>{labels.fundingCalculatorPage.increaseInProfits.toUpperCase()}</b>
                                </div>
                                <br />
                                <div style={{ fontFamily: "Manrope, sans-serif", fontWeight: 550, fontSize: "0.8rem" }}>
                                    {messages.fundingCalculatorPage.increaseInProfitsTooltip(paybackPeriod)}
                                </div>
                            </>
                        }>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                width: isMobile ? "1.5rem" : "2rem",
                                height: isMobile ? "1.5rem" : "2rem",
                                border: `1px solid #989CA270`,
                                borderRadius: "5rem", background: theme.palette.common.white
                            }}
                        >
                            <img src={infoIconQuestion} alt="Info Icon" style={{ width: isMobile ? "0.8rem" : "1rem", height: 'auto' }} />
                        </div>
                    </CustomTooltip>
                </Box>
                {
                    (eligibleFund) ? (
                        <Typography
                            display="flex"
                            alignItems="center"
                            color="#162936"
                            fontFamily="Manrope, sans-serif"
                            fontSize="1.5rem"
                            fontWeight={800}
                            letterSpacing={"-0.72px"}
                        >
                            <Odometer
                                value={`${profitPercentageDelta}%`}
                                loading={showLoader}
                            />
                            <IncreaseIcon />
                        </Typography>
                    ) : (
                        <Skeleton
                            variant="rectangular"
                            width="80%"
                            height="2rem"
                            sx={{ borderRadius: '0.5rem', my: '0.5rem', background: 'linear-gradient(to right, #DCEBE7, #F2F6F7)' }}
                        />
                    )
                }
            </Box >
            <Box
                sx={{
                    background: '#F2F6F7',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: "max-content",
                    borderRadius: "1.25rem",
                    p: "1.25rem",
                    justifyContent: "space-between",
                    alignItems: isSmallMobile ? "center" : "space-between"
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        height: "max-contnet",
                        display: 'flex',
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <Typography
                        variant="body2"
                        fontWeight={800}
                        fontSize='1rem'
                        fontFamily="Manrope, sans-serif"
                        color="#898C8D"
                        letterSpacing='-0.6px'
                    >
                        {`Est. ${labels.fundingCalculatorPage.increaseInCashBalance}`}
                    </Typography>
                    <CustomTooltip
                        content={
                            <>
                                <div style={{ fontFamily: "Manrope, sans-serif", fontWeight: 800, fontSize: "0.65rem" }}>
                                    <b>{labels.fundingCalculatorPage.increaseInCashBalance.toUpperCase()}</b>
                                </div>
                                <br />
                                <div style={{ fontFamily: "Manrope, sans-serif", fontWeight: 550, fontSize: "0.8rem" }}>
                                    {messages.fundingCalculatorPage.increaseInCashBalanceTooltip(paybackPeriod)}
                                </div>
                            </>
                        }>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                width: isMobile ? "1.5rem" : "2rem",
                                height: isMobile ? "1.5rem" : "2rem",
                                border: `1px solid #989CA270`,
                                borderRadius: "5rem", background: theme.palette.common.white
                            }}
                        >
                            <img src={infoIconQuestion} alt="Info Icon" style={{ width: isMobile ? "0.8rem" : "1rem", height: 'auto' }} />
                        </div>
                    </CustomTooltip>

                </Box>
                {(annualFacilitySize) ? (
                    <Typography
                        display="flex"
                        alignItems="center"
                        color="#162936"
                        fontFamily="Manrope, sans-serif"
                        fontSize="1.5rem"
                        fontWeight={800}
                        letterSpacing={"-0.72px"}
                    >
                        <Odometer
                            value={`${cashBalanceIncrease}%`}
                            loading={showLoader}
                        />
                        <IncreaseIcon />
                    </Typography>
                ) : (
                    <Skeleton
                        variant="rectangular"
                        width="80%"
                        height="2rem"
                        sx={{ borderRadius: '0.5rem', my: '0.5rem', background: 'linear-gradient(to right, #DCEBE7, #F2F6F7)' }}
                    />
                )}
            </Box>
        </Box >
    );
};

export default FundingCalculatorSummary;
