import { useLazyDownloadChartCsvQuery } from "@api/charts";

const useChartCsvDownload = () => {
    const [chartCsvDownload, { isLoading, isError }] = useLazyDownloadChartCsvQuery();

    const getChartCsvDownloadById = async (submoduleId: string, chartId: string, filters?: Record<string, string>) => {
        try {
            let params: string[] = [];

            if (filters) {
                for (const [key, value] of Object.entries(filters)) {
                    if (value !== "All") {
                        params.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
                    }
                }
            }
            const queryParams = `?${params.join('&')}`;

            const response = await chartCsvDownload({ submoduleId, chartId, queryParams }).unwrap();

            return response?.data || null;

        } catch (err) {
            console.error('Error downloading chart csv:', err);
        }
    };

    return { getChartCsvDownloadById, isLoading, isError };
};

export default useChartCsvDownload;
