import { FC, useEffect, useRef, useState } from 'react';
import { embedDashboard } from '../../lib/pvx-superset-sdk';
import { FullPageLoader } from '../../components';
import useGuestToken from '@hooks/api/useGuestToken';

interface SupersetDashboardProps {
    data: {
        id: string;
        embedId?: string;
        filterQuery?: string;
    } | null
}

const SupersetDashboard: FC<SupersetDashboardProps> = (props) => {
    const { data: dashboardData } = props;
    const supersetContainerRef = useRef(null);
    const [dashboardLoader, setDashboardLoader] = useState<boolean>(true);

    const { fetchGuestToken: getGuestToken } = useGuestToken();

    // Hook to load embedded dashboard
    useEffect(() => {
        if (dashboardData?.id && dashboardData?.embedId) {
            setDashboardLoader(true)
            getGuestToken(dashboardData.id).then((response) => {
                if (supersetContainerRef.current) {
                    embedDashboard({
                        id: dashboardData.embedId || '',
                        supersetDomain: `${process.env.REACT_APP_SUPERSET_DASHBOARD_URL}`,
                        mountPoint: supersetContainerRef.current,
                        fetchGuestToken: () => Promise.resolve(response.token),
                        dashboardUiConfig: {
                            hideTitle: true,
                            filters: {
                                expanded: false,
                                native: dashboardData.filterQuery || '',
                            },
                        },
                    })
                        .then((dashboard: any) => {
                            const iframe = (supersetContainerRef.current as unknown as HTMLElement).querySelector('iframe');
                            if (iframe) {
                                iframe.style.width = '100%';
                                iframe.style.height = '100%';
                            }

                            setTimeout(() => setDashboardLoader(false), 1000);
                        })
                        .catch((error: any) => console.error('Failed to embed dashboard:', error));
                }
            });
        }
    }, [dashboardData]);

    return (
        <>
            {dashboardLoader && (<FullPageLoader hasSideNav={true} />)}
            <div
                id="pvx-superset-container"
                ref={supersetContainerRef}
                style={{
                    width: '100%',
                    height: '100vh',
                    border: 'none'
                }}
            ></div>
        </>
    );
}

export default SupersetDashboard;
