/* eslint-disable import/no-anonymous-default-export */
export default {
  userDetails: '/users/me',
  userDetailsbyId: (id: string) => `/users/${id}`,
  userModulesById: (id: string) => `/visualization/users/${id}/details`,
  userModules: '/visualization/modules',
  userSubmodules: (moduleId: string) => `/visualization/modules/${moduleId}/submodules`,
  userSubmoduleFilters: (submoduleId: string) => `/visualization/submodules/${submoduleId}/filters`,
  userSubmoduleFiltersValues: (submoduleId: string, filterId: string) => `/visualization/submodules/${submoduleId}/filters/${filterId}/values`,
  userGuestToken: (submoduleId: string) => `/visualization/submodules/${submoduleId}/token`,
  users: '/users',
  companies: '/companies',
  fundingCalculator: 'lead-magnet',
  fundingCalculatorDeatils: (id: string) => `/lead-magnet/${id}`,
  charts: (submoduleId: string) => `/visualization/submodules/${submoduleId}/charts`,
  chartById: (submoduleId: string, chartId: string, queryParams?: string) => `/visualization/submodules/${submoduleId}/charts/${chartId}${queryParams || ''}`,
  customDashboardFilters: (submoduleId: string) => `/visualization/submodules/${submoduleId}/filter-values`,
  chartCsvDownload: (submoduleId: string, chartId: string, queryParams?: string) => `/visualization/submodules/${submoduleId}/charts/${chartId}/download${queryParams || ''}`,
};
