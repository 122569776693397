import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../stores/store';
import { NAV_ROUTES } from '../../constants/navRoutes';
import { getDashboardDataBasedOnUrl } from '../../utils/common';
import EmptyDashboard from './EmptyDashboard';
import { FullPageLoader } from '@components';
import SupersetDashboard from './SupersetDashboard';
import CustomDashboardWrapper from './CustomDashboardWrapper';

const Dashboard: FC = () => {
  const navigate = useNavigate();
  const { module, submodule: subModule } = useParams<{ module?: string; submodule?: string }>();
  const modulesInStore = useSelector((state: RootState) => state?.ModulesData);
  const [isEmptyDashboard, setEmptyDashboard] = useState(false);
  const [dashboardData, setDashboardData] = useState<{ id: string; embedId: string; filterQuery: string; } | null>(null);

  // Hook to set dashboard redirection
  // if no permission then redirect to home page
  // set native filter value for superset dashboard if admin user
  // set dashboard id
  useEffect(() => {
    const redirectModule = modulesInStore?.modules?.[0] || null;
    if (!module && redirectModule && redirectModule?.submodules?.length) {
      setTimeout(() => {
        redirectOnEmptyDashboard();
      }, 1000);
    }

    if (modulesInStore?.loadCount > 1 && !redirectModule) {
      setEmptyDashboard(true);
    }

    if (subModule) {
      const currentDashboardData = getDashboardDataBasedOnUrl(modulesInStore.modules, subModule);
      setDashboardData(currentDashboardData);
    }
  }, [module, modulesInStore, subModule]);

  // Function to redirect to first dashboard in access of user
  const redirectOnEmptyDashboard = () => {
    const redirectModule = modulesInStore?.modules?.[0];
    const redirectSubmodule = redirectModule ? (redirectModule?.submodules?.[0] || null) : null;
    if (redirectModule && redirectSubmodule && !module) {
      const urlModuleParam = redirectModule.name.toLowerCase()
        .replace(/ /g, "_")
        .replace(/\(|\)/g, "");
      navigate(`${NAV_ROUTES.DASHBOARD}/${urlModuleParam}/${redirectSubmodule.url}`);
    }
  };

  // If no dashboards present for user display empty dashboard view
  if (isEmptyDashboard) {
    return (
      <EmptyDashboard />
    );
  }

  return (
    <>
      {dashboardData?.id ? (
        dashboardData?.embedId ? (
          <SupersetDashboard data={dashboardData} />
        ) : (
          <CustomDashboardWrapper data={dashboardData} />
        )
      ) : (
        <FullPageLoader hasSideNav={true} />
      )
      }
    </>
  );
}

export default Dashboard;
