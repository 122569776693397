import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Skeleton,
  useTheme,
  IconButton,
  Dialog,
  DialogContent
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowUpwardIcon from '@mui/icons-material/ExpandLess';
import ArrowDownwardIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';
import logo from '../../assets/icons/pvx-logo-full.svg';
import { NAV_ROUTES } from '../../constants/navRoutes';
import { SideNavProps } from './types';
import { RootState } from '../../stores/store';
import { titles } from '@utils/translations/en';
import sideNavBackgroundImage from "../../assets/images/menu-bg.png";


const SideNav: React.FC<SideNavProps> = ({ customWidth, options, isAdmin, username, onClickLogout }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const userList = useSelector((state: RootState) => state?.UserListData);
  const [openModule, setOpenModule] = useState<number | null>(null);
  const [selectedSubmodule, setSelectedSubmodule] = useState<string | null>(null);
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  const handleModuleClick = (index: number, subOptions?: { label: string; path: string }[]) => {
    if (subOptions) {
      setOpenModule(openModule === index ? null : index);
      if (subOptions.length > 0) {
        const urlModuleParam = options[index].label.toLowerCase()
          .replace(/ /g, "_")
          .replace(/\(|\)/g, "");
        setSelectedSubmodule(subOptions[0].label);
        navigate(`${NAV_ROUTES.DASHBOARD}/${urlModuleParam}/${subOptions[0].path}`);
      }
    } else {
      navigate(options[index].path);
    }
  };

  const handleSubModuleClick = (path: string) => {
    navigate(`${NAV_ROUTES.DASHBOARD}/${path.toLowerCase().replace(/ /g, "_").replace(/\(|\)/g, "")}`);
    setIsMobileOpen(false);
  };

  const isCurrentRoute = (path: string | string[]): boolean => {
    if (Array.isArray(path)) {
      const id = location.pathname.split('/').pop() || '';
      return id ? path.includes(id) : false;
    }
    return location.pathname.includes(path);
  };

  const handleMobileMenuToggle = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  const sideNavOptionBorderStyle = {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 0,
    borderRadius: 'inherit',
    padding: '1px',
    background:
      'linear-gradient(180deg, rgba(127, 188, 173, 0.45) -15.61%, rgba(245, 247, 248, 0.8) 100%)',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    maskComposite: 'exclude',
    WebkitMaskComposite: 'xor',
  };

  return (
    <>
      {/* Mobile View */}
      <Box sx={{ display: { xs: 'block', md: 'none' }, height: '40px' }}>
        <Box
          sx={{
            display: 'flex',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: theme.zIndex.drawer + 2,
            width: '100%',
            backgroundColor: theme.palette.custom_sidebar.background,
            padding: '16px 24px',
            alignItems: 'center'
          }}
        >
          {options?.length ? (
            <IconButton
              onClick={handleMobileMenuToggle}
              sx={{
                position: 'absolute',
                top: 2,
                left: 2,
                color: theme.palette.common.white,
                p: 1
              }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <></>
          )}
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', zIndex: theme.zIndex.drawer + 1, }} >
            <img src={logo} alt={titles.PVX_PARTNERS} style={{ width: 120, height: 'auto' }} />
          </Box>
        </Box>
      </Box>

      {/* Desktop View */}
      <Drawer
        variant="permanent"
        sx={{
          height: "100%",
          width: customWidth,
          maxWidth: "300px",
          flexShrink: 0,
          display: { xs: 'none', md: 'block' },
          '& .MuiDrawer-paper': {
            width: customWidth,
            boxSizing: 'border-box',
            background:
              'linear-gradient(180deg, rgba(0, 0, 0, 1) 100%, rgba(82, 184, 176, 1) 100%)',
            backgroundImage: `url(${sideNavBackgroundImage})`,
            backgroundPosition: "center",
            backgroundSize: 'cover',
            color: theme.palette.common.white,
          },
        }}
      >
        {logo && (<Box sx={{ margin: '12px', padding: '20px', display: 'flex', justifyContent: 'center' }}>
          <img src={logo} alt={titles.PVX_PARTNERS} />
        </Box>)}
        <Box sx={{ mx: '12px', height: '100%', mb: 20, overflowY: 'auto' }}>
          {options?.length ? (
            <List>
              {options.map((option, index) => (
                <div key={index}>
                  <ListItem
                    button
                    onClick={() => handleModuleClick(index, option.subOptions)}
                    sx={{
                      '&:hover': {
                        backgroundColor: '#2F736E1F',
                      },
                      backgroundColor:
                        option?.subOptions?.length === 1 && isCurrentRoute(option?.subOptions?.map((optn) => optn.path) || [])
                          ? '#2F736E1F'
                          : 'inherit',
                      color: '#F6F8F9',
                      borderRadius: '24px',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '12px 16px',
                      my: '0.5rem',
                      overflow: 'hidden',
                      ...(option?.subOptions?.length === 1 && isCurrentRoute(option?.subOptions?.map((optn) => optn.path) || []) && {
                        '&::after': sideNavOptionBorderStyle,
                      }
                      )
                    }}
                  >
                    {option.icon && (<Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: 24,
                        height: 24,
                        marginRight: 1
                      }}
                    >
                      {option.icon}
                    </Box>)}
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center'
                      }}
                    >
                      <ListItemText
                        primary={option.label}
                        primaryTypographyProps={{
                          fontWeight: 700,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontFamily: 'Manrope, sans-serif'
                        }}
                      />
                      {Boolean(option?.subOptions?.length && option.subOptions.length > 1) && (<Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: 24,
                          height: 24,
                          marginRight: 1
                        }}
                      >
                        {openModule === index ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                      </Box>)}
                    </Box>
                  </ListItem>
                  {option.subOptions && option.subOptions.length > 1 && openModule === index && (
                    <List
                      component="div"
                      disablePadding
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        ml: 2
                      }}
                    >
                      {option.subOptions.map((submodule, subIndex) => (
                        <ListItem
                          button
                          key={subIndex}
                          onClick={() => handleSubModuleClick(`${option.label}/${submodule.path}`)}
                          sx={{
                            '&:hover': {
                              backgroundColor: '#2F736E1F',
                              color: '#F6F8F9'
                            },
                            backgroundColor: isCurrentRoute([submodule.path]) ? '#2F736E1F' : 'inherit',
                            color: isCurrentRoute([submodule.path]) ? '#F6F8F9' : '#B4B4B4',
                            borderRadius: '24px',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '12px 16px',
                            width: '90%',
                            my: 1,
                            overflow: 'hidden',
                            ...(isCurrentRoute([submodule.path]) && {
                              '&::after': sideNavOptionBorderStyle
                            }
                            )
                          }}
                        >
                          <ListItemText
                            primary={submodule.label}
                            primaryTypographyProps={{
                              fontWeight: 700,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              fontFamily: 'Manrope, sans-serif'
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </div>
              ))}
            </List>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                boxSizing: 'border-box'
              }}
            >
              {Array.from({ length: 7 }).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  width="90%"
                  height="4%"
                  sx={{ marginTop: 3, borderRadius: 1 }} />
              ))}
            </Box>
          )}
        </Box>
        <Box sx={{ position: 'absolute', bottom: 0, width: '100%' }}>
          {isAdmin && (
            <Box sx={{ display: 'flex', justifyContent: 'center', margin: 1 }}>
              <ListItem
                button
                onClick={() => navigate(NAV_ROUTES.USER_MANAGEMENT)}
                sx={{
                  '&:hover': { backgroundColor: '#2F736E1F' },
                  backgroundColor: isCurrentRoute(NAV_ROUTES.USER_MANAGEMENT) ? '#2F736E1F' : 'trasnparent',
                  color: '#F6F8F9',
                  borderRadius: '24px',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '12px 16px',
                  overflow: 'hidden',
                  ...(isCurrentRoute(NAV_ROUTES.USER_MANAGEMENT) && {
                    '&::after': sideNavOptionBorderStyle
                  })
                }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    fontFamily: 'Manrope, sans-serif',
                    fontWeight: 600,
                    fontSize: '1rem'
                  }}
                  primary={titles.USERS}
                />
                {userList.allUserCount > 0 && (
                  <ListItemText
                    primary={userList.allUserCount}
                    primaryTypographyProps={{
                      fontSize: '1rem',
                      fontFamily: 'Manrope, sans-serif',
                      marginRight: 2,
                      textAlign: 'end',
                      fontWeight: 600,
                    }}
                  />
                )}
              </ListItem>
            </Box>
          )}
          {username && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '20px 16px',
                  background: '#30434C',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 24,
                    height: 24,
                    marginRight: 1
                  }}
                >
                  <UserIcon />
                </Box>
                <ListItemText
                  primary={username}
                  primaryTypographyProps={{
                    fontFamily: 'Manrope, sans-serif',
                    fontWeight: 600,
                    color: '#F6F8F9',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '140px'
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 24,
                    height: 24,
                    marginLeft: 'auto',
                    cursor: 'pointer'
                  }}
                  onClick={onClickLogout}
                >
                  <LogoutIcon />
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Drawer>

      {/* Mobile Drawer Popup */}
      <Dialog
        open={isMobileOpen}
        onClose={handleMobileMenuToggle}
        fullScreen
        sx={{
          position: 'absolute',
          top: '40px',
          left: 0,
          width: customWidth,
          maxWidth: isMobileOpen ? '256px' : '336px',
        }}
        PaperProps={{
          style: {
            color: theme.palette.common.white,
            backgroundImage: `url(${sideNavBackgroundImage})`,
            backgroundSize: 'cover',
            borderRadius: '1.5rem',
          }
        }}
      >
        <DialogContent sx={{ padding: 0 }}>
          <Box sx={{ height: '100%', overflowY: 'auto', p: 1, pb: 8 }}>
            <List>
              {options.map((option, index) => (
                <div key={index}>
                  <ListItem
                    button
                    onClick={() => handleModuleClick(index, option.subOptions)}
                    sx={{
                      '&:focus': {
                        backgroundColor: '#2F736E1F',
                      },
                      backgroundColor:
                        option?.subOptions?.length === 1 &&
                          isCurrentRoute(option?.subOptions?.map((optn) => optn.path) || [])
                          ? '#2F736E1F'
                          : 'inherit',
                      color: '#F6F8F9',
                      borderRadius: '24px',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '12px 16px',
                      my: 1,
                      overflow: 'hidden',
                      ...(option?.subOptions?.length === 1 &&
                        isCurrentRoute(option?.subOptions?.map((optn) => optn.path) || []) && {
                        '&::after': sideNavOptionBorderStyle,
                      }),
                    }}
                  >
                    {option.icon && (<Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: 24,
                        height: 24,
                        marginRight: 1
                      }}
                    >
                      {option.icon}
                    </Box>)}
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center'
                      }}
                    >
                      <ListItemText
                        primary={option.label}
                        primaryTypographyProps={{
                          fontWeight: 700,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontFamily: 'Manrope, sans-serif'
                        }}
                      />
                      {Boolean(option?.subOptions?.length && option.subOptions.length > 1) && (<Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: 24,
                          height: 24,
                          marginRight: 1
                        }}
                      >
                        {openModule === index ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                      </Box>)}
                    </Box>
                  </ListItem>
                  {option.subOptions && option.subOptions.length > 1 && openModule === index && (
                    <List
                      component="div"
                      disablePadding
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        ml: 2
                      }}
                    >
                      {option.subOptions.map((submodule, subIndex) => (
                        <ListItem
                          button
                          key={subIndex}
                          onClick={() => handleSubModuleClick(`${option.label}/${submodule.path}`)}
                          sx={{
                            '&:focus': {
                              backgroundColor: '#2F736E1F',
                              color: '#F6F8F9'
                            },
                            backgroundColor: isCurrentRoute([submodule.path]) ? '#2F736E1F' : 'inherit',
                            color: isCurrentRoute([submodule.path]) ? '#F6F8F9' : '#B4B4B4',
                            borderRadius: '24px',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '8px 16px',
                            width: '90%',
                            my: 1,
                            overflow: 'hidden',
                            ...(isCurrentRoute([submodule.path]) && {
                              '&::after': sideNavOptionBorderStyle
                            }
                            )
                          }}
                        >
                          <ListItemText
                            primary={submodule.label}
                            primaryTypographyProps={{
                              fontWeight: 700,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              fontFamily: 'Manrope, sans-serif'
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </div>
              ))}
            </List>
          </Box>
          <Box sx={{ position: 'absolute', bottom: 0, width: '100%' }}>
            {isAdmin && (
              <Box sx={{ display: 'flex', justifyContent: 'center', margin: 1 }}>
                <ListItem
                  button
                  onClick={() => navigate(NAV_ROUTES.USER_MANAGEMENT)}
                  sx={{
                    '&:focus': { backgroundColor: '#2F736E1F' },
                    backgroundColor: isCurrentRoute(NAV_ROUTES.USER_MANAGEMENT) ? '#2F736E1F' : 'trasnparent',
                    color: '#F6F8F9',
                    borderRadius: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '12px 16px',
                    overflow: 'hidden',
                    ...(isCurrentRoute(NAV_ROUTES.USER_MANAGEMENT) && {
                      '&::after': sideNavOptionBorderStyle
                    })
                  }}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontFamily: 'Manrope, sans-serif',
                      fontWeight: 600,
                      fontSize: '1rem'
                    }}
                    primary={titles.USERS}
                  />
                  {userList.allUserCount > 0 && (
                    <ListItemText
                      primary={userList.allUserCount}
                      primaryTypographyProps={{
                        fontSize: '1rem',
                        fontFamily: 'Manrope, sans-serif',
                        marginRight: 2,
                        textAlign: 'end',
                        fontWeight: 600,
                      }}
                    />
                  )}
                </ListItem>
              </Box>
            )}
            {username && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '20px 16px',
                    background: '#30434C',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: 24,
                      height: 24,
                      marginRight: 1
                    }}
                  >
                    <UserIcon />
                  </Box>
                  <ListItemText
                    primary={username}
                    primaryTypographyProps={{
                      fontFamily: 'Manrope, sans-serif',
                      fontWeight: 600,
                      color: '#F6F8F9',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      maxWidth: '140px'
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: 24,
                      height: 24,
                      marginLeft: 'auto',
                      cursor: 'pointer'
                    }}
                    onClick={onClickLogout}
                  >
                    <LogoutIcon />
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SideNav;
