import React from "react";
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download_icon.svg";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import useChartCsvDownload from "@hooks/api/useChartCsvDownload";

interface PaginationProps {
  page: number;
  size: number;
  total: number;
  handleChange: (page: number) => void;
  fileName: string;
  submoduleId: string;
  chartId: string;
  filterSelection: Record<string, string>;
}

const Pagination: React.FC<PaginationProps> = (props) => {
  const { page, size, total, handleChange, fileName, submoduleId, chartId, filterSelection } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { getChartCsvDownloadById } = useChartCsvDownload();

  const startRow = (page - 1) * size + 1;
  const endRow = Math.min(page * size, total);

  const isFirstPage = page <= 1;
  const isLastPage = page >= Math.ceil(total / size);

  const handleDownload = async () => {
    let downloadLink = "";
    let filters = {};
    if (Object.keys(filterSelection).length > 0) {
      filters = filterSelection;
    }
    const response = await getChartCsvDownloadById(submoduleId, chartId, filters);
    downloadLink = response?.url || '';
    if (!downloadLink) {
      return;
    }
    const link = document.createElement("a");
    link.href = downloadLink;
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", mt: isMobile ? "1rem" : 0, justifyContent: "flex-end" }}>
      <Box sx={{ display: "flex", mr: isMobile ? "1rem" : 0 }}>
        <Button onClick={handleDownload}>
          <DownloadIcon />
        </Button>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            color: "black",
            mx: isMobile ? 1 : 2,
          }}
        >
          {`${startRow}-${endRow} of ${total}`}
        </Typography>
        <Button
          sx={{ color: "#06164B" }}
          onClick={() => handleChange(page - 1)}
          disabled={isFirstPage}
        >
          <KeyboardArrowLeftIcon />
        </Button>
        <Button
          sx={{ color: "#06164B" }}
          onClick={() => handleChange(page + 1)}
          disabled={isLastPage}
        >
          <KeyboardArrowRightIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default Pagination;
