import apiRoutes from '../constants/apiRoutes';
import baseApi from '../services/api';

const user = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCharts: builder.query<any, any>({
      query: (submoduleId) => apiRoutes.charts(submoduleId)
    }),
    getChartById: builder.query<any, any>({
      query: ({ submoduleId, chartId, queryParams }) => apiRoutes.chartById(submoduleId, chartId, queryParams)
    }),
    downloadChartCsv: builder.query<any, any>({
      query: ({ submoduleId, chartId, queryParams }) => apiRoutes.chartCsvDownload(submoduleId, chartId, queryParams)
    })
  })
});

export const { useLazyGetChartsQuery, useLazyGetChartByIdQuery, useLazyDownloadChartCsvQuery } = user;
